html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
a:link {
  color: #424242;
}
a:visited {
  color: #424242;
}
a:hover {
  color: #424242;
}
a:active {
  color: #424242;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.confirm-registration-wrapper {
  margin: 20px;
  padding: 20px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 5px;
}

.wrapper-content {
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.blue-bg {
  background-color: #02c9e3;
}

.loin-text-info {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.err-massage {
  color: #f44336;
  font-size: 14px;
}

.notification-popup-wrapper {
  position: fixed;
  color: #15232a;
  background-color: #ffffff;
  border-left: 5px solid #3f51b5;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 5px;
  top: 95px;
  right: 20px;
  padding: 15px;
  width: 400px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.notification-popup-wrapper .close {
  color: #3f51b5;
  font-size: 20px;
}
.notification-popup-wrapper .close:hover {
  color: #37478d

}

.exeption-popup-wrapper {
  position: fixed;
  color: #403838;
  background-color: #ffffff;
  border-left: 5px solid #dc1f30;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 5px;
  top: 95px;
  right: 20px;
  padding: 15px;
  width: 400px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

}
.exeption-popup-wrapper .close {
  color: #dc1f30;
  font-size: 20px;
}
.exeption-popup-wrapper .close:hover {
  color: #a31523

}

@media (max-width: 700px) {
  .exeption-popup-wrapper{
    width: 80%;
    right: 5%;
    top: 10px;
  }
}

.exeption-popup-close {
  height: 20px;
  width: 20px;
}

.MuiGrid-spacing-xs-3 {
  margin: 0 !important;
  width: 100% !important;
}

.shortText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  line-height: 2;
}

.fullText {
  line-height: 2;
}